<template>
  <div>
    <table>
      <!-- 表头 -->
      <thead>
        <tr class="thead">
          <th>门店名称</th>
          <th>客户保有量</th>
          <th>A客数量</th>
          <th>A客保有数</th>
          <th>A客流失数</th>
          <th>A客保有率</th>
          <th>A客流失预警数</th>
          <th>A客回流数</th>
          <th>A客预警回流率</th>
          <th>A客年消费值</th>
          <th>A客门诊消费</th>
          <th>A客门店消费</th>
          <th>A客门店贡献价值</th>
        </tr>
      </thead>
      <!-- 表格内容 -->
      <tbody>
        <tr>
          <td rowspan="9">{{shopData.shop_name ? shopData.shop_name : aList.shop_name}}</td>
          <td rowspan="9">{{aList.all_baoyou ? aList.all_baoyou : 0}}</td>
          <td class="hand" @click="clickBtn(aList,aList.total_count,'all','A')">{{aList.total_count ? aList.total_count : 0}}</td>
          <td class="hand" @click="clickBtn(aList,aList.retained_count,'baoyou','A')">{{aList.retained_count ? aList.retained_count : 0}}</td>
          <td class="hand" @click="clickBtn(aList,aList.lost_count,'liushi','A')">{{aList.lost_count ? aList.lost_count : 0}}</td>
          <td>{{aList.retention_rate ? aList.retention_rate.replace('%',''): 0}}%</td>
          <td class="hand" @click="clickBtn(aList,aList.lost_warning,'yujing','A')">{{aList.lost_warning ? aList.lost_warning : 0}}</td>
          <td class="hand" @click="clickBtn(aList,aList.reflux_count,'huiliu','A')">{{aList.reflux_count ? aList.reflux_count : 0}}</td>
          <td>{{aList.lost_warning_rate ? aList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{aList.year_amount ? aList.year_amount : 0}}</td>
          <td>{{aList.mz_amount ? aList.mz_amount : 0}}</td>
          <td>{{aList.md_amount ? aList.md_amount : 0}}</td>
          <td>{{aList.gongxian ? aList.gongxian : 0}}</td>
        </tr>
        <tr>
          <th>B客数量</th>
          <th>B客保有数</th>
          <th>B客流失数</th>
          <th>B客保有率</th>
          <th>B客流失预警数</th>
          <th>B客回流数</th>
          <th>B客预警回流率</th>
          <th>B客年消费值</th>
          <th>B客门诊消费</th>
          <th>B客门店消费</th>
          <th>B客门店贡献价值</th>
        </tr>
        <tr>
          <td class="hand" @click="clickBtn(bList,bList.total_count,'all','B')">{{bList.total_count ? bList.total_count : 0}}</td>
          <td class="hand" @click="clickBtn(bList,bList.retained_count,'baoyou','B')">{{bList.retained_count ? bList.retained_count : 0}}</td>
          <td  class="hand" @click="clickBtn(bList,bList.lost_count,'liushi','B')">{{bList.lost_count ? bList.lost_count : 0}}</td>
          <td>{{bList.retention_rate ? bList.retention_rate.replace('%','') : 0}}%</td>
          <td class="hand" @click="clickBtn(bList,bList.lost_warning,'yujing','B')">{{bList.lost_warning ? bList.lost_warning : 0}}</td>
          <td  class="hand" @click="clickBtn(bList,bList.reflux_count,'huiliu','B')">{{bList.reflux_count ? bList.reflux_count : 0}}</td>
          <td>{{bList.lost_warning_rate ? bList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{bList.year_amount ? bList.year_amount : 0}}</td>
          <td>{{bList.mz_amount ? bList.mz_amount : 0}}</td>
          <td>{{bList.md_amount ? bList.md_amount : 0}}</td>
          <td>{{bList.gongxian ? bList.gongxian : 0}}</td>
        </tr>
        
        <tr>
          <th>C客数量</th>
          <th>C客保有数</th>
          <th>C客流失数</th>
          <th>C客保有率</th>
          <th>C客流失预警数</th>
          <th>C客回流数</th>
          <th>C客预警回流率</th>
          <th>C客年消费值</th>
          <th>C客门诊消费</th>
          <th>C客门店消费</th>
          <th>C客门店贡献价值</th>
        </tr>
        <tr>
          <td class="hand" @click="clickBtn(cList,cList.total_count,'all','C')">{{cList.total_count ? cList.total_count : 0}}</td>
          <td class="hand" @click="clickBtn(cList,cList.retained_count,'baoyou','C')">{{cList.retained_count ? cList.retained_count : 0}}</td>
          <td class="hand" @click="clickBtn(cList,cList.lost_count,'liushi','C')">{{cList.lost_count ? cList.lost_count : 0}}</td>
          <td>{{cList.retention_rate ? cList.retention_rate.replace('%','') : 0}}%</td> 
          <td class="hand" @click="clickBtn(cList,cList.lost_warning,'yujing','C')">{{cList.lost_warning ? cList.lost_warning : 0}}</td>
          <td class="hand" @click="clickBtn(cList,cList.reflux_count,'huiliu','C')">{{cList.reflux_count ? cList.reflux_count : 0}}</td>
          <td>{{cList.lost_warning_rate ? cList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{cList.year_amount ? cList.year_amount : 0}}</td>
          <td>{{cList.mz_amount ? cList.mz_amount : 0}}</td>
          <td>{{cList.md_amount ? cList.md_amount : 0}}</td>
          <td>{{cList.gongxian ? cList.gongxian : 0}}</td>
        </tr>
        <tr>
          <th>D客数量</th>
          <th>D客保有数</th>
          <th>D客流失数</th>
          <th>D客保有率</th>
          <th>D客流失预警数</th>
          <th>D客回流数</th>
          <th>D客预警回流率</th>
          <th>D客年消费值</th>
          <th>D客门诊消费</th>
          <th>D客门店消费</th>
          <th>D客门店贡献价值</th>
        </tr>
        <tr>
          <td class="hand" @click="clickBtn(dList,dList.total_count,'all','D')">{{dList.total_count ? dList.total_count : 0}}</td>
          <td class="hand" @click="clickBtn(dList,dList.retained_count,'baoyou','D')">{{dList.retained_count ? dList.retained_count : 0}}</td>
          <td  class="hand" @click="clickBtn(dList,dList.lost_count,'liushi','D')">{{dList.lost_count ? dList.lost_count : 0}}</td>
          <td>{{dList.retention_rate ? dList.retention_rate.replace('%','') : 0}}%</td>
          <td  class="hand" @click="clickBtn(dList,dList.lost_warning,'yujing','D')">{{dList.lost_warning ? dList.lost_warning : 0}}</td>
          <td  class="hand" @click="clickBtn(dList,dList.reflux_count,'huiliu','D')">{{dList.reflux_count ? dList.reflux_count : 0}}</td>
          <td>{{dList.lost_warning_rate ? dList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{dList.year_amount ? dList.year_amount : 0}}</td>
          <td>{{dList.mz_amount ? dList.mz_amount : 0}}</td>
          <td>{{dList.md_amount ? dList.md_amount : 0}}</td>
          <td>{{dList.gongxian ? dList.gongxian : 0}}</td>
        </tr>
        <tr>
          <th>E客数量</th>
          <th>E客保有数</th>
          <th>E客流失数</th>
          <th>E客保有率</th>
          <th>E客流失预警数</th>
          <th>E客回流数</th>
          <th>E客预警回流率</th>
          <th>E客年消费值</th>
          <th>E客门诊消费</th>
          <th>E客门店消费</th>
          <th>E客门店贡献价值</th>
        </tr>
        <tr>
          <td class="hand" @click="clickBtn(eList,eList.total_count,'all','E')">{{eList.total_count ? eList.total_count : 0}}</td>
          <td class="hand" @click="clickBtn(eList,eList.retained_count,'baoyou','E')">{{eList.retained_count ? eList.retained_count : 0}}</td>
          <td class="hand" @click="clickBtn(eList,eList.lost_count,'liushi','E')">{{eList.lost_count ? eList.lost_count : 0}}</td>
          <td>{{eList.retention_rate ? eList.retention_rate.replace('%','') : 0}}%</td>
          <td class="hand" @click="clickBtn(eList,eList.lost_warning,'yujing','E')">{{eList.lost_warning ? eList.lost_warning : 0}}</td>
          <td class="hand" @click="clickBtn(eList,eList.reflux_count,'huiliu','E')">{{eList.reflux_count ? eList.reflux_count : 0}}</td>
          <td>{{eList.lost_warning_rate ? eList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{eList.year_amount ? eList.year_amount : 0}}</td>
          <td>{{eList.mz_amount ? eList.mz_amount : 0}}</td>
          <td>{{eList.md_amount ? eList.md_amount : 0}}</td>
          <td>{{eList.gongxian ? eList.gongxian : 0}}</td>
        </tr>
        <tr>
          <td>合计</td>
          <td>{{sumList.all_baoyou ? sumList.all_baoyou : 0}}</td>
          <td>{{sumList.total_count ? sumList.total_count : 0}}</td>
          <td>{{sumList.retained_count ? sumList.retained_count : 0}}</td>
          <td>{{sumList.lost_count ? sumList.lost_count : 0}}</td>
          <td>{{sumList.retention_rate ? sumList.retention_rate.replace('%','') : 0}}%</td>
          <td>{{sumList.lost_warning ? sumList.lost_warning : 0}}</td>
          <td>{{sumList.reflux_count ? sumList.reflux_count : 0}}</td>
          <td>{{sumList.lost_warning_rate ? sumList.lost_warning_rate.replace('%','') : 0}}%</td>
          <td>{{sumList.year_amount ? sumList.year_amount : 0}}</td>
          <td>{{sumList.mz_amount ? sumList.mz_amount : 0}}</td>
          <td>{{sumList.md_amount ? sumList.md_amount : 0}}</td>
          <td>{{sumList.gongxian ? sumList.gongxian : 0}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
name: 'shopTable',
props: {
  shopData: {
    type: Object,
    default: () => {} 
  }
},
data () {
  return {
    aList: {},
    bList: {},
    cList: {},
    dList: {},
    eList: {},
    sumList: {}
  }
},
watch: {
  shopData: {
    handler(val) {
      this.shopData = val;
      console.log(val,'val')
      this.getMetadata();
    },
    deep: true
  }
},
mounted () {
this.getMetadata();
},
methods: {
  getMetadata(){
    console.log(this.shopData.list,'this.shopData')
    if(this.shopData.list){
      this.shopData.list.forEach((item) => {
        if(item.level == 'A'){
          this.aList = item
        }else if(item.level == 'B'){
          this.bList = item
        } else if(item.level == 'C'){
          this.cList = item
        }else if(item.level == 'D'){
          this.dList = item
        }else if(item.level == 'E'){
          this.eList = item
        }else if(item.shop_name == '合计'){
          this.sumList = item
        }
      })
    }
  },
  clickBtn(row,count,type,level){
    console.log(count,"count")
    if(count == 0 || count == undefined){
      this.$message({
        message: '暂无数据',
        type: 'warning'
      });
    }else{
      let query = row
      this.$set(query, 'type', type);
      this.$set(query, 'level', level);
      let route = this.$router.resolve({ name: "保有数据表明细", query:query });
      window.open(route.href, '_blank');
    }
  },

}
}
</script>

<style lang="scss" scoped>
.hand {
  cursor: pointer;
  &:hover {
    color: #33bea7;
  }
}
table {
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #DADADA;
    width: 2%;
    height: 20px;
    text-align: center;
    color: #000000;
    font-size: 12px;
    padding: 0.8% 1%;
    background: #ffffff;
}
th{
  color: #909399;
  background: rgb(249, 250, 252);
}
.thead{
  th{
    background:#E2EFFC; 
    &:nth-child(-n + 2){
      background:#FFE8D6;
    }
    &:nth-child(6){
      position: relative;
      background: #CAE0F5;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
        }
    }
    &:nth-child(9){
      position: relative;
      background: #CAE0F5;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
        }
    }
  }
}
tbody{
  tr th{
    background:#E2EFFC; 
  }
  tr td{
    background:#ffffff; 
  }
  tr:last-child{
    td{
      background:#FFF3D6; 
      &:nth-child(-n + 2){
        background:#FFE8D6;
      }
      &:nth-child(6){
        position: relative;
        background: #FFE8D6;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }

      }
      &:nth-child(9){
        position: relative;
        background: #FFE8D6;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
      }
      }
  }
  tr:first-child{
    td{
      background:#ffffff; 
      &:nth-child(-n + 2){
        background:#FFFCF4;
      }
      &:nth-child(6){
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
        
      }
      &:nth-child(9){ 
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
      }
      }
  }
  tr:nth-child(2n){
    th{
      &:nth-child(4){
        background: #CAE0F5;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
        
      }
      &:nth-child(7){ 
        background: #CAE0F5;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
      }
      }
  }
  tr:not(:first-child):nth-child(2n+1){
    td{
      background:#ffffff; 
      &:nth-child(4){
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
        
      }
      &:nth-child(7){ 
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0,  0.03); /* 半透明黑色 */
          }
      }
      }
  }
}
</style>
