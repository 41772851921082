<template>
  <div>
    <el-row class="bgW">
      <el-col :span="20"  v-if="admin_level !== 1">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="客户类型" >
            <el-select
              v-model="search.level"
              style="margin-left: 20px;"
              placeholder="请选择"
              @change="levelChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4" :class=" admin_level == 1 ? 'btnBox1' : 'btnBox'">
        <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportSingleExcel" v-if="admin_level == 3">门店数据导出</el-button>
        <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 20px;">
      <el-alert
        type="info"
        title="ABCD客数量:门店成交的所有新单客户（客户首单金额大于等于500）滚动一年范围内在门店和门诊累计消费金额大于等于50000\15000小于50000\5000小于15000\500小于5000。"
        :closable="false">
      </el-alert>
      <el-alert
        type="info"
        title="ABCD客保有:A\B\C\D客数量在最近三个月内门店/门诊有消耗或消费任何一个即为保有。"
        :closable="false">
      </el-alert>
    </el-row>
  <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          border
          size="small"
          v-loading="loading"
          :cell-style="admin_level == 2 ? cellStyle : cellStyle1"
          :header-cell-style="$style.rowClass"
          v-if="admin_level == 2 || admin_level == 3"
        >
          <ElTableColumn label="市场名称">
            <template slot-scope="{row}">
              <div class="hand"  @click="clickMarketBtn(row)"  v-if="admin_level != 2 && row.market_name !== '合计' ">
                {{row.market_name}}
              </div>
              <div v-else>
                {{row.market_name}}
              </div>
            </template>
          </ElTableColumn>
          <el-table-column label="门店名称" v-if="admin_level == 2">
            <template slot-scope="{row}">
              <div class="hand"  @click="clickShop(row)" >
                {{row.shop_name == '合计' ? '' : row.shop_name}}
              </div>
            </template>
          </el-table-column>

          <ElTableColumn label="客户保有量"  prop="all_baoyou" />

          <el-table-column>
            <template #header>
              {{search.level}}客数量
            </template>
            <template slot-scope="{row}">
              <div  class="hand" @click="clickBtn(row,row.total_count,'all')"  v-if="row.market_name !== '合计' ">
                {{row.total_count}}
              </div>
              <div v-else>
                {{row.total_count}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}客保有数
            </template>
            <template slot-scope="{row}">
              <div class="hand" @click="clickBtn(row,row.retained_count,'baoyou')"  v-if="row.market_name !== '合计'">
                {{row.retained_count}}
              </div>
              <div v-else>
                {{row.retained_count}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}客流失数
            </template>
            <template slot-scope="{row}">
              <div  class="hand" @click="clickBtn(row,row.lost_count,'liushi')"  v-if="row.market_name !== '合计'">
                {{row.lost_count}}
              </div>
              <div v-else>
                {{row.lost_count}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}客保有率
            </template>
            <template slot-scope="{row}">
              {{row.retention_rate.replace('%','')}}%
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}客流失预警数
            </template>
            <template slot-scope="{row}">
              <div  class="hand" @click="clickBtn(row,row.lost_warning,'yujing')"  v-if="row.market_name !== '合计'">
                {{row.lost_warning}}
              </div>
              <div v-else>
                {{row.lost_warning}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}客回流数
            </template>
            <template slot-scope="{row}">
              <div  class="hand" @click="clickBtn(row,row.reflux_count,'huiliu')"  v-if="row.market_name !== '合计'">
                {{row.reflux_count}}
              </div>
              <div v-else>
                {{row.reflux_count}}
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template #header>
              {{search.level}}预警回流率
            </template>
            <template slot-scope="{row}">
              {{row.lost_warning_rate.replace('%','')}}%
            </template>
          </el-table-column>
          <el-table-column prop="year_amount" >
            <template #header>
              {{search.level}}客年度消费值
            </template>
          </el-table-column>
          <el-table-column prop="mz_amount" >
            <template #header>
              {{search.level}}客门诊消费
            </template>
          </el-table-column>
          <el-table-column prop="md_amount" >
            <template #header>
              {{search.level}}客门店消费
            </template>
          </el-table-column>
          <el-table-column prop="gongxian" > 
            <template #header>
              {{search.level}}客门店贡献值
            </template>
          </el-table-column>
        </el-table>
        <!-- 区域经理-门店名称 -->
        <!--  -->
        <el-dialog
          title="详细信息"
          :visible.sync="dialogVisible"
          v-if="dialogVisible"
          width="70%">
          <div style="margin: 0 auto;width:90%;">
            <shop-table :shopData="shopList"></shop-table>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
          </span>
        </el-dialog>
        <!-- 门店店长 -->
        <shop-table v-if="admin_level == 1" :shopData="shopList"></shop-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getStore } from "@/utils/store";
import { getRetainList } from "@/api/statistics";
import  ShopTable  from "./components/shopTable";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  level = 'A'; 
}

export default {
name: 'keepCustomerData',
components: {
  ShopTable
},
data () {
  return {
    spanArr: [], // 存储合并信息的数组
    admin_level: getStore('admin_level'),
    dialogVisible: false,
    search: new Search(), // 检索条件
    list: [], // 数据展示
    shopList: {},
    loading:false,
    exportS: false,
    options: [
      {
        value: 'A',
        label: 'A'
      },
      {
        value: 'B',
        label: 'B'
      },
      {
        value: 'C',
        label: 'C'
      },
      {
        value: 'D',
        label: 'D'
      },
      {
        value: 'E',
        label: 'E'
      }
    ]
  }
},

mounted () {
  this.getList();
},
methods: {
  cellStyle({columnIndex}){
    if(columnIndex <= 2){
        return 'background:#D9E1F4;text-align:center'
    }
    if(columnIndex > 2 && columnIndex <= 9){
        return 'background:#FDE9D9;text-align:center'
    }
    if(columnIndex >= 10 && columnIndex <= 13){
        return 'background:#D8E4BC;text-align:center'
    }
    return '';
  },
  cellStyle1({columnIndex}){
    if(columnIndex <= 1){
        return 'background:#D9E1F4;text-align:center'
    }
    if(columnIndex > 1 && columnIndex <= 8){
        return 'background:#FDE9D9;text-align:center'
    }
    if(columnIndex >= 9 && columnIndex <= 13){
        return 'background:#D8E4BC;text-align:center'
    }
    return '';
  },
  levelChange(){
    this.getList();
  },
  getList() {
    this.loading = true
    getRetainList({
      'level':this.admin_level == 1 ? '' : this.search.level,
      "admin_level":this.admin_level,
    }).then(res => {
      this.list = res.data.list;
      this.shopList = this.admin_level == 1 ?  res.data : {};      
      this.loading = false
    });
  },
  clickShop(row){
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    getRetainList({
      'shop_id':row.shop_id,
      "admin_level":this.admin_level,
    }).then(res => {
      loading.close();
      this.dialogVisible = true ;
      this.shopList = res.data;

    });
  },
  clickMarketBtn(row){
      let query = row
      this.$set(query, 'level', this.search.level);
      let route = this.$router.resolve({ name: "市场保有数据表明细", query:query });
      window.open(route.href, '_blank');
  },
  clickBtn(row,count,type){
    console.log(row,count,type,"11");
    if(count == 0){
      this.$message({
        message: '暂无数据',
        type: 'warning'
      });
    }else{
      let query = row
      this.$set(query, 'type', type);
      this.$set(query, 'level', this.search.level);
      let route = this.$router.resolve({ name: "保有数据表明细", query:query });
      window.open(route.href, '_blank');
    }
  },
  // 个别权限导出
  async exportSingleExcel() {
    // this.exportS = true;
    const { data } =  await getRetainList({
      'level': this.search.level,
      "is_export_admin": 1
    });
    if(data){
      window.open(data.url);
      this.exportS = false
    }

  },


  // 导出表格
  async exportExcel() {
    // this.exportS = true;
    const { data } =  await getRetainList({
      'level': this.admin_level == 1 ? '' : this.search.level,
      "admin_level": this.admin_level,
      "is_export": "1",
    });
    if(data){
      window.open(data.url);
      this.exportS = false
    }

  }
}
}
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}
.btnBox1 {
  text-align: left;
}

.hand {
  cursor: pointer;
  &:hover {
    color: #33bea7;
  }
}
</style>
